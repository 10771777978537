/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = PartOfShipment  2 = All
 */
export const CargoPalletizeType = {
  None: 0,
  PartOfShipment: 1,
  All: 2,
} as const;

export type CargoPalletizeType =
  (typeof CargoPalletizeType)[keyof typeof CargoPalletizeType];

/**
 * Maps a CargoPalletizeType enumeration value to its corresponding translation key.
 *
 * @param { CargoPalletizeType } cargoPalletizeType -
 *        The enumeration value representing the type of CargoPalletizeType.
 * @returns {string} The translation key corresponding to the given CargoPalletizeType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('CargoPalletizeType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromCargoPalletizeType(CargoPalletizeType.PartOfShipment);
 * console.log(translationKey); // 'CargoPalletizeType.PartOfShipment'
 * console.log(translocoService.translate(translationKey)) // "Translation of CargoPalletizeType.PartOfShipment"
 *
 * // using in a template:
 * {{ translationKeyFromCargoPalletizeType(CargoPalletizeType.PartOfShipment) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('CargoPalletizeType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromCargoPalletizeType(
  cargoPalletizeType: CargoPalletizeType,
) {
  switch (cargoPalletizeType) {
    case CargoPalletizeType.None:
      return 'CargoPalletizeType.None';
    case CargoPalletizeType.PartOfShipment:
      return 'CargoPalletizeType.PartOfShipment';
    case CargoPalletizeType.All:
      return 'CargoPalletizeType.All';
    default:
      return '';
  }
}
