import { type PlatformEnvironmentConfiguration } from '@cca-environment';
import { defaultExcludedReturnUrls } from './partials';

export const environment: PlatformEnvironmentConfiguration = {
  production: false,
  serviceWorker: true,
  baseTitle: 'CtrlChain',
  includeTestLanguage: true,
  enableHubspot: true,
  transloco: {
    missingHandler: {
      logMissingKey: true,
    },
    cache: {
      // 24 * 60 * 60 * 1000 = 1 day
      shortLifetime: 86_400_000,

      // 6 * 7 * 24 * 60 * 60 * 10000 = 6 weeks
      longLifetime: 36_288_000_000,
    },
  },
  features: {
    showTrackingSimulation: true,
    authentication: {
      urlAfterLogin: '/',
      urlAfterLogout: '/authentication',
      excludedReturnUrls: defaultExcludedReturnUrls,
    },
    logger: {
      logSuccessFullReportsToConsole: true,
      reportCsp: true,
      reportError: true,
      reportHttp: true,
      ignoredHttpCodes: [401],
    },
  },
  msalConfig: {
    auth: {
      clientID: 'b8d05b1e-4764-407a-9ed2-677243ee621c',
      authority:
        'https://login.microsoftonline.com/85ffde86-f494-4a60-a93e-f957c8d672ed',
    },
    apiConfig: {
      scopes: ['user.read', 'mailboxsettings.read'],
      uri: 'https://graph.microsoft.com/v1.0',
    },
  },
};
