/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Fixed  2 = Flexible
 */
export const VolumeFrequencyType = {
  Undefined: 0,
  Fixed: 1,
  Flexible: 2,
} as const;

export type VolumeFrequencyType =
  (typeof VolumeFrequencyType)[keyof typeof VolumeFrequencyType];

/**
 * Maps a VolumeFrequencyType enumeration value to its corresponding translation key.
 *
 * @param { VolumeFrequencyType } volumeFrequencyType -
 *        The enumeration value representing the type of VolumeFrequencyType.
 * @returns {string} The translation key corresponding to the given VolumeFrequencyType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('VolumeFrequencyType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromVolumeFrequencyType(VolumeFrequencyType.Fixed);
 * console.log(translationKey); // 'VolumeFrequencyType.Fixed'
 * console.log(translocoService.translate(translationKey)) // "Translation of VolumeFrequencyType.Fixed"
 *
 * // using in a template:
 * {{ translationKeyFromVolumeFrequencyType(VolumeFrequencyType.Fixed) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('VolumeFrequencyType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromVolumeFrequencyType(
  volumeFrequencyType: VolumeFrequencyType,
) {
  switch (volumeFrequencyType) {
    case VolumeFrequencyType.Undefined:
      return 'VolumeFrequencyType.Undefined';
    case VolumeFrequencyType.Fixed:
      return 'VolumeFrequencyType.Fixed';
    case VolumeFrequencyType.Flexible:
      return 'VolumeFrequencyType.Flexible';
    default:
      return '';
  }
}
