/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Brokerage  1 = ManagedTransport  2 = SAAS
 */
export const LogisticServiceProviderType = {
  Brokerage: 0,
  ManagedTransport: 1,
  SAAS: 2,
} as const;

export type LogisticServiceProviderType =
  (typeof LogisticServiceProviderType)[keyof typeof LogisticServiceProviderType];

/**
 * Maps a LogisticServiceProviderType enumeration value to its corresponding translation key.
 *
 * @param { LogisticServiceProviderType } logisticServiceProviderType -
 *        The enumeration value representing the type of LogisticServiceProviderType.
 * @returns {string} The translation key corresponding to the given LogisticServiceProviderType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('LogisticServiceProviderType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromLogisticServiceProviderType(LogisticServiceProviderType.ManagedTransport);
 * console.log(translationKey); // 'LogisticServiceProviderType.ManagedTransport'
 * console.log(translocoService.translate(translationKey)) // "Translation of LogisticServiceProviderType.ManagedTransport"
 *
 * // using in a template:
 * {{ translationKeyFromLogisticServiceProviderType(LogisticServiceProviderType.ManagedTransport) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('LogisticServiceProviderType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromLogisticServiceProviderType(
  logisticServiceProviderType: LogisticServiceProviderType,
) {
  switch (logisticServiceProviderType) {
    case LogisticServiceProviderType.Brokerage:
      return 'LogisticServiceProviderType.Brokerage';
    case LogisticServiceProviderType.ManagedTransport:
      return 'LogisticServiceProviderType.ManagedTransport';
    case LogisticServiceProviderType.SAAS:
      return 'LogisticServiceProviderType.SAAS';
    default:
      return '';
  }
}
