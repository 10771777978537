/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = Email  2 = MobilePushMessage  4 = WebPushMessage  8 = Webhook
 */
export const NotificationEmitType = {
  None: 0,
  Email: 1,
  MobilePushMessage: 2,
  WebPushMessage: 4,
  Webhook: 8,
} as const;

export type NotificationEmitType =
  (typeof NotificationEmitType)[keyof typeof NotificationEmitType];

/**
 * Maps a NotificationEmitType enumeration value to its corresponding translation key.
 *
 * @param { NotificationEmitType } notificationEmitType -
 *        The enumeration value representing the type of NotificationEmitType.
 * @returns {string} The translation key corresponding to the given NotificationEmitType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('NotificationEmitType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromNotificationEmitType(NotificationEmitType.Email);
 * console.log(translationKey); // 'NotificationEmitType.Email'
 * console.log(translocoService.translate(translationKey)) // "Translation of NotificationEmitType.Email"
 *
 * // using in a template:
 * {{ translationKeyFromNotificationEmitType(NotificationEmitType.Email) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('NotificationEmitType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromNotificationEmitType(
  notificationEmitType: NotificationEmitType,
) {
  switch (notificationEmitType) {
    case NotificationEmitType.None:
      return 'NotificationEmitType.None';
    case NotificationEmitType.Email:
      return 'NotificationEmitType.Email';
    case NotificationEmitType.MobilePushMessage:
      return 'NotificationEmitType.MobilePushMessage';
    case NotificationEmitType.WebPushMessage:
      return 'NotificationEmitType.WebPushMessage';
    case NotificationEmitType.Webhook:
      return 'NotificationEmitType.Webhook';
    default:
      return '';
  }
}
