/**
 * Notification Service
 * <p>The Notification Service is used to notify users via Email, Push and or SMS messages</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Date grouping enum  1 = Today  2 = Week  3 = Older
 */
export const DateGroup = {
  Today: 1,
  Week: 2,
  Older: 3,
} as const;

export type DateGroup = (typeof DateGroup)[keyof typeof DateGroup];

/**
 * Maps a DateGroup enumeration value to its corresponding translation key.
 *
 * @param { DateGroup } dateGroup -
 *        The enumeration value representing the type of DateGroup.
 * @returns {string} The translation key corresponding to the given DateGroup.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('DateGroup')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromDateGroup(DateGroup.Week);
 * console.log(translationKey); // 'DateGroup.Week'
 * console.log(translocoService.translate(translationKey)) // "Translation of DateGroup.Week"
 *
 * // using in a template:
 * {{ translationKeyFromDateGroup(DateGroup.Week) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('DateGroup')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromDateGroup(dateGroup: DateGroup) {
  switch (dateGroup) {
    case DateGroup.Today:
      return 'DateGroup.Today';
    case DateGroup.Week:
      return 'DateGroup.Week';
    case DateGroup.Older:
      return 'DateGroup.Older';
    default:
      return '';
  }
}
