/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = Perishable  2 = Food  3 = FoodAndPerishable
 */
export const CargoProductType = {
  None: 0,
  Perishable: 1,
  Food: 2,
  FoodAndPerishable: 3,
} as const;

export type CargoProductType =
  (typeof CargoProductType)[keyof typeof CargoProductType];

/**
 * Maps a CargoProductType enumeration value to its corresponding translation key.
 *
 * @param { CargoProductType } cargoProductType -
 *        The enumeration value representing the type of CargoProductType.
 * @returns {string} The translation key corresponding to the given CargoProductType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('CargoProductType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromCargoProductType(CargoProductType.Perishable);
 * console.log(translationKey); // 'CargoProductType.Perishable'
 * console.log(translocoService.translate(translationKey)) // "Translation of CargoProductType.Perishable"
 *
 * // using in a template:
 * {{ translationKeyFromCargoProductType(CargoProductType.Perishable) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('CargoProductType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromCargoProductType(
  cargoProductType: CargoProductType,
) {
  switch (cargoProductType) {
    case CargoProductType.None:
      return 'CargoProductType.None';
    case CargoProductType.Perishable:
      return 'CargoProductType.Perishable';
    case CargoProductType.Food:
      return 'CargoProductType.Food';
    case CargoProductType.FoodAndPerishable:
      return 'CargoProductType.FoodAndPerishable';
    default:
      return '';
  }
}
