/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = SideLoading  2 = DoorLoading  3 = TopLoading
 */
export const CargoLoadingType = {
  None: 0,
  SideLoading: 1,
  DoorLoading: 2,
  TopLoading: 3,
} as const;

export type CargoLoadingType =
  (typeof CargoLoadingType)[keyof typeof CargoLoadingType];

/**
 * Maps a CargoLoadingType enumeration value to its corresponding translation key.
 *
 * @param { CargoLoadingType } cargoLoadingType -
 *        The enumeration value representing the type of CargoLoadingType.
 * @returns {string} The translation key corresponding to the given CargoLoadingType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('CargoLoadingType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromCargoLoadingType(CargoLoadingType.SideLoading);
 * console.log(translationKey); // 'CargoLoadingType.SideLoading'
 * console.log(translocoService.translate(translationKey)) // "Translation of CargoLoadingType.SideLoading"
 *
 * // using in a template:
 * {{ translationKeyFromCargoLoadingType(CargoLoadingType.SideLoading) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('CargoLoadingType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromCargoLoadingType(
  cargoLoadingType: CargoLoadingType,
) {
  switch (cargoLoadingType) {
    case CargoLoadingType.None:
      return 'CargoLoadingType.None';
    case CargoLoadingType.SideLoading:
      return 'CargoLoadingType.SideLoading';
    case CargoLoadingType.DoorLoading:
      return 'CargoLoadingType.DoorLoading';
    case CargoLoadingType.TopLoading:
      return 'CargoLoadingType.TopLoading';
    default:
      return '';
  }
}
