import { Injectable, inject } from '@angular/core';
import { type EntityLoading } from '@cca-common/cdk';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { type Observable, exhaustMap } from 'rxjs';
import { SequenceStore } from '../sequence-store';
import { SequenceOptionsService } from '@cca-infra/sequence-management/v1';

export interface EnumOptionViewModel {
  value: BookingServiceType;
  name: string | undefined;
}

export enum BookingServiceType {
  Undefined,
  Lane,
  FTL,
  Courier,
  CreateTender,
  FCL,
}

export enum FCLTransportType {
  Undefined,
  Import,
  Export,
  Shunt,
}

export type BookingServiceState = EntityLoading<
  EnumOptionViewModel[],
  'serviceOptions'
>;

const initialState: BookingServiceState = {
  serviceOptions: [],
  error: false,
  loading: false,
};

@Injectable()
export class BookingServicesStore extends ComponentStore<BookingServiceState> {
  private readonly optionsService = inject(SequenceOptionsService);
  private readonly store = inject(SequenceStore);

  constructor() {
    super(initialState);
  }

  readonly serviceOptions = this.selectSignal((state) => {
    return state.serviceOptions;
  });

  readonly loading = this.selectSignal((state) => {
    return state.loading;
  });

  readonly error = this.selectSignal((state) => {
    return state.error;
  });

  readonly retries = this.selectSignal((state) => {
    return state.retries ?? 0;
  });

  /**
   * Updaters
   */
  readonly setServiceOptions = this.updater(
    (
      state,
      bookingServicesOptions: EnumOptionViewModel[],
    ): BookingServiceState => {
      return {
        ...state,
        serviceOptions: bookingServicesOptions,
        loading: false,
        retries: 0,
      };
    },
  );

  readonly setLoading = this.updater(
    (state, newLoadingState: boolean): BookingServiceState => {
      return {
        ...state,
        loading: newLoadingState,
      };
    },
  );

  readonly setErrorLoading = this.updater(
    (state, newErrorState: boolean): BookingServiceState => {
      let retries = state?.retries ?? 0;
      if (newErrorState) {
        retries++;
      }

      return {
        ...state,
        error: newErrorState,
        loading: false,
        retries: retries,
      };
    },
  );

  /**
   * Effects
   */

  readonly getBookingServiceOptions = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      exhaustMap(() => {
        this.setErrorLoading(false);
        this.setLoading(true);
        return this.optionsService
          .getSequenceServiceOptions(this.store.sequenceName)
          .pipe(
            tapResponse({
              next: (options: EnumOptionViewModel[]) => {
                if (options) this.setServiceOptions(options);
              },
              error: () => {
                this.setErrorLoading(true);
              },
              finalize: () => {
                this.setLoading(false);
              },
            }),
          );
      }),
    ),
  );

  readonly getCarrierServiceOptions = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      exhaustMap(() => {
        this.setErrorLoading(false);
        this.setLoading(true);
        return this.optionsService
          .getCarrierServiceOptions(this.store.sequenceName)
          .pipe(
            tapResponse({
              next: (options: EnumOptionViewModel[]) => {
                if (options) this.setServiceOptions(options);
              },
              error: () => {
                this.setErrorLoading(true);
              },
              finalize: () => {
                this.setLoading(false);
              },
            }),
          );
      }),
    ),
  );
}
