import { signalStoreFeature, withHooks, type } from '@ngrx/signals';
import { type RememberState } from '@cca-common/core';
import { enableDevMenu } from '../../enable';

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withAutoToggle<_>() {
  return signalStoreFeature(
    { state: type<RememberState>() },
    withHooks({
      onInit(store) {
        if (store.remember()) {
          enableDevMenu();
        }
      },
    }),
  );
}
