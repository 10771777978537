/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = No  1 = Yes  2 = YesUnder1000  3 = YesOver1000
 */
export const HazardousShipmentType = {
  No: 0,
  Yes: 1,
  YesUnder1000: 2,
  YesOver1000: 3,
} as const;

export type HazardousShipmentType =
  (typeof HazardousShipmentType)[keyof typeof HazardousShipmentType];

/**
 * Maps a HazardousShipmentType enumeration value to its corresponding translation key.
 *
 * @param { HazardousShipmentType } hazardousShipmentType -
 *        The enumeration value representing the type of HazardousShipmentType.
 * @returns {string} The translation key corresponding to the given HazardousShipmentType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('HazardousShipmentType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromHazardousShipmentType(HazardousShipmentType.Yes);
 * console.log(translationKey); // 'HazardousShipmentType.Yes'
 * console.log(translocoService.translate(translationKey)) // "Translation of HazardousShipmentType.Yes"
 *
 * // using in a template:
 * {{ translationKeyFromHazardousShipmentType(HazardousShipmentType.Yes) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('HazardousShipmentType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromHazardousShipmentType(
  hazardousShipmentType: HazardousShipmentType,
) {
  switch (hazardousShipmentType) {
    case HazardousShipmentType.No:
      return 'HazardousShipmentType.No';
    case HazardousShipmentType.Yes:
      return 'HazardousShipmentType.Yes';
    case HazardousShipmentType.YesUnder1000:
      return 'HazardousShipmentType.YesUnder1000';
    case HazardousShipmentType.YesOver1000:
      return 'HazardousShipmentType.YesOver1000';
    default:
      return '';
  }
}
