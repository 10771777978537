/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = StandTrailer  2 = SwapTrailer  3 = SelfLoading
 */
export const TrailerOperationType = {
  Undefined: 0,
  StandTrailer: 1,
  SwapTrailer: 2,
  SelfLoading: 3,
} as const;

export type TrailerOperationType =
  (typeof TrailerOperationType)[keyof typeof TrailerOperationType];

/**
 * Maps a TrailerOperationType enumeration value to its corresponding translation key.
 *
 * @param { TrailerOperationType } trailerOperationType -
 *        The enumeration value representing the type of TrailerOperationType.
 * @returns {string} The translation key corresponding to the given TrailerOperationType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('TrailerOperationType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromTrailerOperationType(TrailerOperationType.StandTrailer);
 * console.log(translationKey); // 'TrailerOperationType.StandTrailer'
 * console.log(translocoService.translate(translationKey)) // "Translation of TrailerOperationType.StandTrailer"
 *
 * // using in a template:
 * {{ translationKeyFromTrailerOperationType(TrailerOperationType.StandTrailer) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('TrailerOperationType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromTrailerOperationType(
  trailerOperationType: TrailerOperationType,
) {
  switch (trailerOperationType) {
    case TrailerOperationType.Undefined:
      return 'TrailerOperationType.Undefined';
    case TrailerOperationType.StandTrailer:
      return 'TrailerOperationType.StandTrailer';
    case TrailerOperationType.SwapTrailer:
      return 'TrailerOperationType.SwapTrailer';
    case TrailerOperationType.SelfLoading:
      return 'TrailerOperationType.SelfLoading';
    default:
      return '';
  }
}
