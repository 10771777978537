/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Within1Hour  2 = Within2Hours  3 = At  4 = Between  5 = Before  6 = After
 */
export const TimeOptionType = {
  Undefined: 0,
  Within1Hour: 1,
  Within2Hours: 2,
  At: 3,
  Between: 4,
  Before: 5,
  After: 6,
} as const;

export type TimeOptionType =
  (typeof TimeOptionType)[keyof typeof TimeOptionType];

/**
 * Maps a TimeOptionType enumeration value to its corresponding translation key.
 *
 * @param { TimeOptionType } timeOptionType -
 *        The enumeration value representing the type of TimeOptionType.
 * @returns {string} The translation key corresponding to the given TimeOptionType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('TimeOptionType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromTimeOptionType(TimeOptionType.Within1Hour);
 * console.log(translationKey); // 'TimeOptionType.Within1Hour'
 * console.log(translocoService.translate(translationKey)) // "Translation of TimeOptionType.Within1Hour"
 *
 * // using in a template:
 * {{ translationKeyFromTimeOptionType(TimeOptionType.Within1Hour) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('TimeOptionType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromTimeOptionType(
  timeOptionType: TimeOptionType,
) {
  switch (timeOptionType) {
    case TimeOptionType.Undefined:
      return 'TimeOptionType.Undefined';
    case TimeOptionType.Within1Hour:
      return 'TimeOptionType.Within1Hour';
    case TimeOptionType.Within2Hours:
      return 'TimeOptionType.Within2Hours';
    case TimeOptionType.At:
      return 'TimeOptionType.At';
    case TimeOptionType.Between:
      return 'TimeOptionType.Between';
    case TimeOptionType.Before:
      return 'TimeOptionType.Before';
    case TimeOptionType.After:
      return 'TimeOptionType.After';
    default:
      return '';
  }
}
