/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Pallet  2 = Box  3 = Pipe  4 = Drum
 */
export const HandleUnitSubType = {
  Undefined: 0,
  Pallet: 1,
  Box: 2,
  Pipe: 3,
  Drum: 4,
} as const;

export type HandleUnitSubType =
  (typeof HandleUnitSubType)[keyof typeof HandleUnitSubType];

/**
 * Maps a HandleUnitSubType enumeration value to its corresponding translation key.
 *
 * @param { HandleUnitSubType } handleUnitSubType -
 *        The enumeration value representing the type of HandleUnitSubType.
 * @returns {string} The translation key corresponding to the given HandleUnitSubType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('HandleUnitSubType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromHandleUnitSubType(HandleUnitSubType.Pallet);
 * console.log(translationKey); // 'HandleUnitSubType.Pallet'
 * console.log(translocoService.translate(translationKey)) // "Translation of HandleUnitSubType.Pallet"
 *
 * // using in a template:
 * {{ translationKeyFromHandleUnitSubType(HandleUnitSubType.Pallet) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('HandleUnitSubType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromHandleUnitSubType(
  handleUnitSubType: HandleUnitSubType,
) {
  switch (handleUnitSubType) {
    case HandleUnitSubType.Undefined:
      return 'HandleUnitSubType.Undefined';
    case HandleUnitSubType.Pallet:
      return 'HandleUnitSubType.Pallet';
    case HandleUnitSubType.Box:
      return 'HandleUnitSubType.Box';
    case HandleUnitSubType.Pipe:
      return 'HandleUnitSubType.Pipe';
    case HandleUnitSubType.Drum:
      return 'HandleUnitSubType.Drum';
    default:
      return '';
  }
}
