/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = AVC  2 = CMR
 */
export const FCLTermsAndConditionsType = {
  Undefined: 0,
  AVC: 1,
  CMR: 2,
} as const;

export type FCLTermsAndConditionsType =
  (typeof FCLTermsAndConditionsType)[keyof typeof FCLTermsAndConditionsType];

/**
 * Maps a FCLTermsAndConditionsType enumeration value to its corresponding translation key.
 *
 * @param { FCLTermsAndConditionsType } fCLTermsAndConditionsType -
 *        The enumeration value representing the type of FCLTermsAndConditionsType.
 * @returns {string} The translation key corresponding to the given FCLTermsAndConditionsType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('FCLTermsAndConditionsType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromFCLTermsAndConditionsType(FCLTermsAndConditionsType.AVC);
 * console.log(translationKey); // 'FCLTermsAndConditionsType.AVC'
 * console.log(translocoService.translate(translationKey)) // "Translation of FCLTermsAndConditionsType.AVC"
 *
 * // using in a template:
 * {{ translationKeyFromFCLTermsAndConditionsType(FCLTermsAndConditionsType.AVC) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('FCLTermsAndConditionsType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromFCLTermsAndConditionsType(
  fCLTermsAndConditionsType: FCLTermsAndConditionsType,
) {
  switch (fCLTermsAndConditionsType) {
    case FCLTermsAndConditionsType.Undefined:
      return 'FCLTermsAndConditionsType.Undefined';
    case FCLTermsAndConditionsType.AVC:
      return 'FCLTermsAndConditionsType.AVC';
    case FCLTermsAndConditionsType.CMR:
      return 'FCLTermsAndConditionsType.CMR';
    default:
      return '';
  }
}
