import { inject, computed, Injector, type Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap, type SubscriptionLike } from 'rxjs';
import {
  DevStoreRegistryStore,
  withOptionalRemembering,
} from '@cca-common/core';
import { devMenuEnabled } from '../enable';
import { DateTime } from 'luxon';

type MonitorState = { trackedStores: string[]; logTracing: boolean };

function getValueFromSymbol(obj: unknown, symbol: symbol) {
  if (typeof obj === 'object' && obj && symbol in obj) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (obj as { [key: symbol]: any })[symbol];
  }
}

function getStoreSignal(store: unknown): Signal<unknown> {
  const [signalStateKey] = Object.getOwnPropertySymbols(store);
  if (!signalStateKey) {
    throw new Error('Cannot find State Signal');
  }

  return getValueFromSymbol(store, signalStateKey);
}

const DEV_STORE_MONITOR_STORAGE_KEY = 'DEV-TRACKED-STORES';

export const DevStoreMonitorStore = signalStore(
  { providedIn: 'root' },
  withState<MonitorState>({ trackedStores: [], logTracing: false }),
  withComputed((store) => {
    const registry = inject(DevStoreRegistryStore);
    return {
      availableStores: computed(() => Object.values(registry.stores())),
      entries: computed(() => {
        const availableStores = Object.keys(registry.stores());
        const trackedStores = store.trackedStores();

        return availableStores.map((storeName) => {
          return {
            name: storeName,
            tracked: trackedStores.includes(storeName),
          };
        });
      }),
    };
  }),
  withMethods((store) => ({
    updateLogTracing(logTracing: boolean) {
      patchState(store, () => ({
        logTracing: logTracing,
      }));
    },

    addTrackedStore(storeIdentifier: string): void {
      patchState(store, () => ({
        trackedStores: Array.from(
          new Set([...store.trackedStores(), storeIdentifier]),
        ),
      }));
    },

    removeTrackedStore(storeIdentifier: string): void {
      patchState(store, () => ({
        trackedStores: store
          .trackedStores()
          .filter((str) => str !== storeIdentifier),
      }));
    },

    clear(): void {
      patchState(store, () => ({
        trackedStores: [],
      }));
    },

    _logState(storeName: string, state: unknown) {
      console.group?.(`Store ${storeName}`);
      console.log(state);
      const d = DateTime.now();
      console.log(`${d.toString()}`);

      // Log the trace (callstack) of how this got changed
      if (store.logTracing()) {
        console.trace();
      }
      console.groupEnd?.();
    },
  })),
  withMethods((store) => {
    const registry = inject(DevStoreRegistryStore);
    const injector = inject(Injector);
    const trackedStoresObservablesMap = new Map<string, SubscriptionLike>();

    return {
      _watchStores: rxMethod<{ name: string; tracked: boolean }[]>(
        pipe(
          tap((entries) => {
            const storeMap = registry.stores();
            for (const entry of entries) {
              if (entry.name in storeMap && entry.tracked) {
                if (!trackedStoresObservablesMap.has(entry.name)) {
                  const stateSource = storeMap[entry.name];
                  const storeSignal = getStoreSignal(stateSource);
                  const subscription = toObservable(storeSignal, {
                    injector: injector,
                  })
                    .pipe(tap((state) => store._logState(entry.name, state)))
                    .subscribe(); // Subscribe and store the subscription

                  trackedStoresObservablesMap.set(entry.name, subscription);
                }
              } else {
                // when stopping tracking clear the subscriptions and delete from observable map
                if (trackedStoresObservablesMap.has(entry.name)) {
                  const subscription = trackedStoresObservablesMap.get(
                    entry.name,
                  );
                  subscription?.unsubscribe();
                  trackedStoresObservablesMap.delete(entry.name);
                }
              }
            }

            // Keep track of store names that should be retained
            const activeStoreNames = new Set(
              entries.map((entry) => entry.name),
            );

            // Remove and unsubscribe from stores that are no longer available eg we navigated to a page
            // and previous tracked stores no longer exist, we should cleanup those subscriptions etc
            for (const [
              storeName,
              subscription,
            ] of trackedStoresObservablesMap.entries()) {
              if (!activeStoreNames.has(storeName)) {
                // Unsubscribe from the observable if it's no longer being tracked
                subscription.unsubscribe();

                trackedStoresObservablesMap.delete(storeName); // Remove from the map

                console.log(`stop tracking: ${storeName}`);
              }
            }
          }),
        ),
      ),
    };
  }),

  withHooks((store) => {
    return {
      onInit() {
        store._watchStores(store.entries);
      },
    };
  }),

  withOptionalRemembering(DEV_STORE_MONITOR_STORAGE_KEY),
  withHooks((store) => {
    return {
      onInit() {
        store.updateRemember(devMenuEnabled);
      },
    };
  }),
);
