<ng-container *transloco="let t; read: 'autocompleteField'">
  <ng-select
    [items]="items ?? []"
    [multiple]="multiple"
    [closeOnSelect]="closeOnSelect"
    [clearable]="clearable"
    [loading]="_loading"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [appearance]="appearance"
    [fixedPlaceholder]="fixedPlaceholder"
    [appendTo]="appendTo"
    [trackByFn]="trackByFn"
    [groupBy]="groupBy"
    [placeholder]="placeholder"
    [typeahead]="typeahead!"
    [minTermLength]="minTermLength"
    [editableSearchTerm]="editableSearchTerm"
    [typeToSearchText]="typeToSearchText"
    [formControl]="formControl"
    [dropdownPosition]="dropdownPosition"
    [clearAllText]="''"
    [markFirst]="markFirst"
    [addTagText]="addTagText"
    [loadingText]="loadingText"
    [hideSelected]="hideSelected"
    [selectOnTab]="selectOnTab"
    [openOnEnter]="openOnEnter"
    [maxSelectedItems]="maxSelectedItems"
    [bufferAmount]="bufferAmount"
    [virtualScroll]="virtualScroll"
    [selectableGroup]="selectableGroup"
    [selectableGroupAsModel]="selectableGroupAsModel"
    [searchFn]="searchFn"
    [clearOnBackspace]="clearOnBackspace"
    [labelForId]="labelForId"
    [inputAttrs]="inputAttrs"
    [tabIndex]="tabIndex"
    [readonly]="readonly"
    [searchWhileComposing]="searchWhileComposing"
    [searchable]="false"
    [groupValue]="groupValue"
    [keyDownFn]="keyDownFn"
    [addTag]="addTag"
    [compareWith]="compareWith"
    (blur)="blurEvent.emit($event)"
    (focus)="focusEvent.emit($event)"
    (change)="onChange($event)"
    (open)="openEvent.emit($event)"
    (close)="closeEvent.emit($event)"
    (clear)="clearEvent.emit($event)"
    (add)="addEvent.emit($event)"
    (remove)="removeEvent.emit($event)"
    (search)="searchEvent.emit($event)"
    (scroll)="scrollEvent.emit($event)"
    (scrollToEnd)="scrollToEndEvent.emit($event)"
    class="ng-select-custom m-0 p-0"
    [class.ng-select-required]="isRequired"
    [class.ng-select-show-chips]="showChips"
    #ngSelect
  >
    @if (((items ?? []).length > 5 || typeahead) && searchable) {
      <ng-template ng-header-tmp>
        <div class="my-2 flex items-center gap-1">
          @if (multiple) {
            <mat-checkbox
              color="primary"
              [checked]="allSelected()"
              [indeterminate]="partiallySelected()"
              (change)="toggleAll($event)"
            >
            </mat-checkbox>
          }
          <mat-form-field
            appearance="outline"
            [subscriptSizing]="'dynamic'"
            class="grow"
          >
            <input
              #searchInput
              matInput
              [placeholder]="t('typeToSearch')"
              (input)="ngSelect.filter($any($event.target).value)"
            />
            <cca-icon
              matSuffix
              icon="search"
              class="text-neutral-caption"
            ></cca-icon>
          </mat-form-field>
          @if (multiple && clearAll && ngSelect.selectedItems.length > 0) {
            <button
              mat-icon-button
              [ngxTippy]="t('clearAll')"
              [tippyClassName]="'cca-tippy'"
              [tippyProps]="tooltipOptions"
              (click)="ngSelect.clearModel()"
            >
              <cca-icon
                icon="circle-xmark"
                class="text-neutral-caption"
              ></cca-icon>
            </button>
          }
        </div>
      </ng-template>
    }

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <!-- <div class="ng-value">
        {{ bindLabel ? items[0][bindLabel] : items[0] }}
      </div>
      <div class="ng-value" *ngIf="items.length > 1">
        <span class="ng-value-label">{{ items.length - 1 }} more...</span>
      </div> -->
      @if (!showStaticLabel) {
        @if (showChips) {
          @for (item of items; track item) {
            <div class="ng-value">
              <span class="me-2">
                <ng-template
                  [ngTemplateOutlet]="labelWithTemplate"
                  [ngTemplateOutletContext]="{
                    text: bindLabel ? (item[bindLabel] | transloco) : item,
                    wrapText: false,
                    item: item,
                  }"
                />
              </span>
              @if (!readonly) {
                <cca-icon
                  [icon]="'circle-xmark'"
                  class="-mt-[1px] cursor-pointer text-lg !leading-4 hover:text-neutral-title"
                  (click)="clear(item); ngSelect.close()"
                ></cca-icon>
              }
            </div>
          }
        } @else {
          @if (showTooltip) {
            @if (items.length === 1) {
              <div
                class="ng-value"
                [ngxTippy]="itemsTemplate"
                [tippyClassName]="'cca-tippy'"
                [tippyProps]="tooltipOptions"
              >
                <ng-template
                  [ngTemplateOutlet]="labelWithTemplate"
                  [ngTemplateOutletContext]="{
                    text: bindLabel
                      ? (items[0][bindLabel] | transloco)
                      : items[0],
                    wrapText: false,
                    item: items[0],
                  }"
                />
              </div>
            } @else {
              <div
                class="ng-value"
                [ngxTippy]="itemsTemplate"
                [tippyClassName]="'cca-tippy'"
                [tippyProps]="tooltipOptions"
              >
                {{ items.length }} selected
              </div>
            }
          } @else {
            @if (items.length === 1) {
              <div class="ng-value">
                <ng-template
                  [ngTemplateOutlet]="labelWithTemplate"
                  [ngTemplateOutletContext]="{
                    text: bindLabel
                      ? (items[0][bindLabel] | transloco)
                      : items[0],
                    wrapText: false,
                    item: items[0],
                  }"
                />
              </div>
            } @else {
              <div class="ng-value">{{ items.length }} selected</div>
            }
          }
        }
      }
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
      @if (!showStaticLabel) {
        <ng-template
          [ngTemplateOutlet]="labelWithTemplate"
          [ngTemplateOutletContext]="{
            text: bindLabel ? (item[bindLabel] | transloco) : item,
            wrapText: false,
            item: item,
          }"
        />
      }
    </ng-template>

    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index"
      let-search="searchTerm"
    >
      @if (multiple) {
        <div class="flex">
          <mat-checkbox color="primary" [ngModel]="item$.selected">
            <ng-template
              [ngTemplateOutlet]="labelWithTemplate"
              [ngTemplateOutletContext]="{
                text: bindLabel ? (item[bindLabel] | transloco) : item,
                wrapText: true,
                item: item,
              }"
            />
          </mat-checkbox>
        </div>
      } @else {
        <div class="flex w-full items-center gap-4">
          <!-- Badge on the left if conditions are met -->
          @if (
            showBadgeFn(item) && badgeTemplate && badgePlacement === 'left'
          ) {
            <ng-template
              [ngTemplateOutlet]="badgeTemplate"
              [ngTemplateOutletContext]="{ item: item }"
            />
          }
          <span class="grow" style="text-wrap: wrap; overflow-wrap: anywhere">
            {{ bindLabel ? (item[bindLabel] | transloco) : item }}
          </span>
          <!-- Badge on the right if conditions are met -->
          @if (
            showBadgeFn(item) && badgeTemplate && badgePlacement === 'right'
          ) {
            <ng-template
              [ngTemplateOutlet]="badgeTemplate"
              [ngTemplateOutletContext]="{ item: item }"
            />
          }
          <!-- Show check if the item is selected -->
          @if (item$.selected) {
            <mat-pseudo-checkbox
              [state]="'checked'"
              [appearance]="'minimal'"
            ></mat-pseudo-checkbox>
          }
        </div>
      }
    </ng-template>

    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
      <div class="ng-option loading-notfound-text">{{ t('noOptions') }}</div>
    </ng-template>

    <ng-template ng-typetosearch-tmp let-searchTerm="searchTerm">
      <div class="ng-option loading-notfound-text">{{ typeToSearchText }}</div>
    </ng-template>

    <ng-template ng-loadingtext-tmp let-searchTerm="searchTerm">
      <div class="ng-option loading-notfound-text">{{ t('loading') }}</div>
    </ng-template>

    <ng-template #itemsTemplate let-name let-data="data">
      <div class="my-1 !flex max-h-52 flex-col gap-2 overflow-auto">
        @for (item of ngSelect.selectedValues; track $index) {
          <span>
            <ng-template
              [ngTemplateOutlet]="labelWithTemplate"
              [ngTemplateOutletContext]="{
                text: bindLabel ? (item[bindLabel] | transloco) : item,
                wrapText: false,
                item: item,
              }"
            />
          </span>
        }
      </div>
    </ng-template>
  </ng-select>
  <div class="mat-form-field-appearance-outline">
    <div class="mat-form-field-subscript-wrapper">
      <ng-content select="footer"></ng-content>
    </div>
  </div>
</ng-container>

<ng-template
  #labelWithTemplate
  let-text="text"
  let-wrapText="wrapText"
  let-item="item"
>
  <div class="flex w-full items-center gap-2">
    <!-- Badge on the left if conditions are met -->
    @if (showBadgeFn(item) && badgeTemplate && badgePlacement === 'left') {
      <ng-template
        [ngTemplateOutlet]="badgeTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      />
    }
    <span
      [ngClass]="{
        'overflow-hidden text-ellipsis whitespace-nowrap': !wrapText,
      }"
    >
      {{ text }}
    </span>
    <!-- Badge on the right if conditions are met -->
    @if (showBadgeFn(item) && badgeTemplate && badgePlacement === 'right') {
      <ng-template
        [ngTemplateOutlet]="badgeTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      />
    }
  </div>
</ng-template>
