/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Warehouse  2 = Business  3 = Address  4 = Other  5 = Port
 */
export const LocationType = {
  Undefined: 0,
  Warehouse: 1,
  Business: 2,
  Address: 3,
  Other: 4,
  Port: 5,
} as const;

export type LocationType = (typeof LocationType)[keyof typeof LocationType];

/**
 * Maps a LocationType enumeration value to its corresponding translation key.
 *
 * @param { LocationType } locationType -
 *        The enumeration value representing the type of LocationType.
 * @returns {string} The translation key corresponding to the given LocationType.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('LocationType')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromLocationType(LocationType.Warehouse);
 * console.log(translationKey); // 'LocationType.Warehouse'
 * console.log(translocoService.translate(translationKey)) // "Translation of LocationType.Warehouse"
 *
 * // using in a template:
 * {{ translationKeyFromLocationType(LocationType.Warehouse) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('LocationType')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromLocationType(locationType: LocationType) {
  switch (locationType) {
    case LocationType.Undefined:
      return 'LocationType.Undefined';
    case LocationType.Warehouse:
      return 'LocationType.Warehouse';
    case LocationType.Business:
      return 'LocationType.Business';
    case LocationType.Address:
      return 'LocationType.Address';
    case LocationType.Other:
      return 'LocationType.Other';
    case LocationType.Port:
      return 'LocationType.Port';
    default:
      return '';
  }
}
