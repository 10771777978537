/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NoRepeat  1 = Every1Week  2 = Every2Weeks  3 = Every3Weeks  4 = Every4Weeks
 */
export const ShipmentFrequency = {
  NoRepeat: 0,
  Every1Week: 1,
  Every2Weeks: 2,
  Every3Weeks: 3,
  Every4Weeks: 4,
} as const;

export type ShipmentFrequency =
  (typeof ShipmentFrequency)[keyof typeof ShipmentFrequency];

/**
 * Maps a ShipmentFrequency enumeration value to its corresponding translation key.
 *
 * @param { ShipmentFrequency } shipmentFrequency -
 *        The enumeration value representing the type of ShipmentFrequency.
 * @returns {string} The translation key corresponding to the given ShipmentFrequency.
 *                   Returns an empty string (`''`) if the value is not recognized.
 *
 * @example
 * // at the `@Component({...})` decorator
 * providers: [provideTranslocoScope('ShipmentFrequency')]
 *
 * // getting a translationKey:
 * const translationKey = translationKeyFromShipmentFrequency(ShipmentFrequency.Every1Week);
 * console.log(translationKey); // 'ShipmentFrequency.Every1Week'
 * console.log(translocoService.translate(translationKey)) // "Translation of ShipmentFrequency.Every1Week"
 *
 * // using in a template:
 * {{ translationKeyFromShipmentFrequency(ShipmentFrequency.Every1Week) | transloco }}
 *
 * @remarks
 * - requires that the scope for this function is correctly provided: `provideTranslocoScope('ShipmentFrequency')`
 * - requires that the translation key has been translated by the backend.
 */
export function translationKeyFromShipmentFrequency(
  shipmentFrequency: ShipmentFrequency,
) {
  switch (shipmentFrequency) {
    case ShipmentFrequency.NoRepeat:
      return 'ShipmentFrequency.NoRepeat';
    case ShipmentFrequency.Every1Week:
      return 'ShipmentFrequency.Every1Week';
    case ShipmentFrequency.Every2Weeks:
      return 'ShipmentFrequency.Every2Weeks';
    case ShipmentFrequency.Every3Weeks:
      return 'ShipmentFrequency.Every3Weeks';
    case ShipmentFrequency.Every4Weeks:
      return 'ShipmentFrequency.Every4Weeks';
    default:
      return '';
  }
}
